module.exports = {
  DEBUG: process.env.NODE_ENV === 'development',

  siteName: 'Brilliant Floor Care',
  author: 'Balwinder Klair',
  description: 'Top-Rated cleaners and Decorators in Cannda',
  // siteUrl: 'https://www.midasbrush-decorating.co.uk/',
  logo: '/images/logo.png',
  // metaLogo: 'https://www.midasbrush-decorating.co.uk/images/logo.jpg',

  telephone: '6042180937',
  email: 'brilliantfloorcare@gmail.com',
  address: '12209 68A Ave Surrey V3V OX3',
  twitter: 'https://twitter.com/',
  facebook: 'https://www.facebook.com/',
  instagram: 'https://www.instagram.com/',

  type: 'website',
  googleAnalytics: ' ',
  backgroundColor: '#e0e0e0',
  themeColor: '#0d8021',
  sanityId: 'ptvca8vc',
  mailchimp: '',
};
